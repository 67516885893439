<template>
    <div class="vendor-profile-reviews">
        <vendor-reviews :vendor="vendor"></vendor-reviews>
    </div>
</template>
<script>
import VendorReviews from "@/components/Vendor/VendorReviews";
export default {
    props: {
        vendor: {
            type: Object
        }
    },
    components: {
        VendorReviews
    }
};
</script>
