<template>
    <div class="vendor-profile-index">
        <div class="logo" v-if="vendor && vendor.vendor && vendor.vendor.logo">
            <img :src="vendor.vendor.logo | googleimage" alt class="logo-image">
        </div>
        <div class="vendorname" v-if="vendor && vendor.vendor && !vendor.vendor.logo">
            <h1>{{vendor.name}}</h1>
        </div>
        <md-tabs @change="tabChange" ref="tabs" v-if="vendor">
            <md-tab :md-active="tabIndex==0" md-label="Products/Services">
                <vendor-profile-products :vendor="vendor" v-if="tabIndex==0"></vendor-profile-products>
            </md-tab>
            <md-tab :md-active="tabIndex==1" md-label="About">
                <vendor-profile-about :vendor="vendor" v-if="tabIndex==1"></vendor-profile-about>
            </md-tab>
            <md-tab :md-active="tabIndex==2" md-label="Reviews">
                <vendor-profile-reviews :vendor="vendor" v-if="tabIndex==2"></vendor-profile-reviews>
            </md-tab>
        </md-tabs>
        <div v-else-if="!loading && !vendor">
            <p>Vendor Not Found</p>
        </div>
    </div>
</template>
<script>
import VendorProfileAbout from "@/components/Vendor/VendorProfileAbout";
import VendorProfileProducts from "@/components/Vendor/VendorProfileProducts";
import VendorProfileReviews from "@/components/Vendor/VendorProfileReviews";
export default {
    name: "vendor-profile-index",
    props: {
        vendor_name: {
            type: String,
            required: true
        },
        page: {
            type: String,
            default: "detail" //detail products reviews
        }
    },
    components: {
        VendorProfileAbout,
        VendorProfileProducts,
        VendorProfileReviews
    },
    data() {
        return {
            loading: true,
            vendor: null,
            tabIndex: 0,
            ready: false
        };
    },

    watch: {
        $route: {
            immediate: true,
            handler() {
                switch (this.page) {
                    case "products":
                        this.tabIndex = 0;
                        break;
                    case "about":
                        this.tabIndex = 1;
                        break;

                    case "reviews":
                        this.tabIndex = 2;
                        break;
                }

                setTimeout(() => {
                    this.ready = true;
                }, 0);
            }
        }
    },

    methods: {
        tabChange(index) {
            if (!this.ready) {
                return;
            }

            if (index == 0) {
                this.$router.push(`/${this.vendor_name}/products`);
            }
            if (index == 1) {
                this.$router.push(`/${this.vendor_name}/about`);
            }
            if (index == 2) {
                this.$router.push(`/${this.vendor_name}/reviews`);
            }
        },
        async getVendor() {
            let result = await this.$store.dispatch("crud/get", {
                api: `users/vendor`,
                params: {
                    name: this.vendor_name
                }
            });
            this.loading = false;
            if (!result.active) {
                this.$store.commit("setMessage", "vendor not active");
                return;
            }
            this.vendor = result;
        }
    },
    created() {
        this.getVendor();
    }
};
</script>
<style lang="less">
.vendor-profile-index {
    .logo {
        text-align: center;
        img {
            height: 100px;
        }
    }
    .vendorname {
        text-align: center;
    }
    .md-tabs {
        .md-tabs-navigation {
            padding-left: 20px;
            .md-tabs-navigation-container {
                .md-tab-header {
                    border-radius: 13px 13px 2px 2px;
                    border: 1px solid #1068bf;
                    // box-shadow: 0 0 2px 1px orange;
                    margin-right: 10px;
                    &.md-active {
                        // background: orange;
                        color: #1068bf !important;
                    }
                }
            }
            .md-tab-indicator {
                display: none;
            }
        }
    }
}
</style>

