<template>
    <div class="vendor-profile-products">
        <side-menu :link="false" :vendorGroupCategories="vendorGroupCategories" @selected="menuSelected"></side-menu>
        <product-view :category="selectedCategory && selectedCategory.slug" :level="selectedCategory && selectedCategory.level && selectedCategory.level+''" :vendor="vendor._id" v-if="vendor"></product-view>
    </div>
</template>
<script>
import SideMenu from "@/components/Menu/SideMenu";
import ProductView from "@/components/Product/ProductView";
export default {
    props: {
        vendor: {
            type: Object
        }
    },
    components: {
        ProductView,
        SideMenu
    },
    data() {
        return {
            selectedCategory: null,
            vendorGroupCategories: null
        };
    },
    methods: {
        menuSelected(c) {
            this.selectedCategory = c;
        },
        async loadVendorCategories() {
            let result = await this.$store.dispatch("crud/get", {
                api: "categories",
                params: {
                    vendor: this.vendor._id,
                    group: 1
                }
            });
            this.vendorGroupCategories = result.data || result;
            console.info(result);
        }
    },
    created() {
        this.loadVendorCategories();
    }
};
</script>
<style lang="less">
.vendor-profile-products {
    display: flex;
    .product-view {
        flex: 1;
    }
}

@media (max-width: 600px) {
    .vendor-profile-products {
        .side-menu {
            display: none;
        }
    }
}
</style>

