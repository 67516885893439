<template>
    <md-card class="product-card" v-if="i">
        <router-link :to="productLink">
            <div class="badges">
                <div class="badge sale-badge" v-if="product.sale">
                    <md-icon>star</md-icon>
                    <span class="txt">SALE</span>
                    <md-icon>star</md-icon>
                </div>
                <div class="badge hot-badge" v-if="product.hot">
                    <md-icon>star</md-icon>
                    <span class="txt">HOT</span>
                    <md-icon>star</md-icon>
                </div>
                <div class="badge new-badge" v-if="product.new">
                    <md-icon>star</md-icon>
                    <span class="txt">NEW</span>
                    <md-icon>star</md-icon>
                </div>
            </div>
            <md-card-media class="image-container" md-ratio="4:3">
                <img :src="i.image | googleimage" alt="product image" />
            </md-card-media>
            <md-card-header>
                <h2 class="md-title">{{ product.name | truncate(30) }}</h2>
                <div class="price">
                    <span>{{ i.price | currency }}</span>
                    <small v-if="product.rental">/{{ product.rentalInterval | intervalName }}</small>
                </div>
                <div class="vendor__name" v-if="showVendorName">
                    <!-- <span>{{ product.vendor_name }}</span> -->
                    <router-link :to="`/${product.vendor_name}/products`">{{ product.vendor_name }}</router-link>
                </div>
            </md-card-header>
        </router-link>
    </md-card>
</template>
<script>
import CartButtons from "@/components/Cart/CartButtons";
import CartDateButtons from "@/components/Cart/CartDateButtons";
import { mapGetters } from "vuex";
export default {
    props: ["product"],
    components: {
        CartButtons,
        CartDateButtons
    },

    computed: {
        ...mapGetters({
            vendorDisplay: "setting/vendorDisplay",
            user: "user/user",
            hasRole: "user/hasRole"
        }),
        showVendorName() {
            return this.user ? true : this.vendorDisplay.value;
        },
        productLink() {
            return `/${this.product.slug}/${this.product._id}`;
        },
        i() {
            return this.product.variants.length && this.product.variants[0];
        }
    }
};
</script>
<style lang="less">
.product-card {
    overflow: hidden;
    width: 300px;
    margin: 10px;
    text-align: center;
    cursor: pointer;

    a {
        text-decoration: none !important;
    }

    .md-card-media {
        margin: auto;
    }

    .md-card-header {
        margin-bottom: 0 !important;
    }

    .md-title {
        width: 100%;
        font-size: 20px;
        font-weight: 400;
        color: #323232;
        padding: 0px;
        margin: 0;
        text-transform: uppercase;
        max-height: 30px;
        overflow: hidden;
        color: rgb(50, 126, 224);
        cursor: pointer;
    }

    .price {
        margin: 20px 20px 0;
        font-size: 20px;
        display: block;
        font-style: italic;
        color: rgba(0, 0, 0, 0.87);
        font-weight: 400;
    }

    .del {
        margin: 20px;
    }

    .variant {
        padding: 10px;
        margin: 4px;
        box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.4);
        cursor: pointer;
    }

    .vendor__name {
        margin-top: 20px;
        color: #323232;
    }

    .price del {
        font-size: 12px;
        color: #999;
    }

    .badges {
        height: 24px;
        padding: 2px 15px;
        display: flex;
        justify-content: center;

        .badge {
            flex: 1;
            border-radius: 10px;
            color: white;
            font-size: 10px;
            margin: 0px 5px;
            height: 20px;
            box-shadow: 0 2px 7px rgba(0, 0, 0, 0.48);
            .md-icon {
                font-size: 10px;
                min-width: 12px;
                width: 12px;
                margin-top: -2px;
            }
        }
        .sale-badge {
            background-color: #ef364c;
        }
        .new-badge {
            background-color: rgb(50, 126, 224);
        }

        .hot-badge {
            background-color: #ff8800;
        }
    }
}

@media (max-width: 600px) {
    .product-card {
        width: 40%;
        max-width: 190px;
        .image-container {
            // width: 140px;
            height: 105px;
        }
        .md-title {
            font-size: 15px;
            line-height: 15px;
            max-height: 30px;
            // height: auto;
        }
        .price {
            margin: 5px auto;
            font-size: 15px;
        }
        .center {
            .vendor__name {
                margin-top: 0px;
            }
        }
        .md-card-media + .md-card-header {
            padding-top: 12px;
            padding: 8px;
        }
    }
}

.vendorMode {
    .price {
        color: #323232;
    }
}
</style>
