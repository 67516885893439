<template>
    <div class="vendor-profile-about">
        <md-card v-if="vendor">
            <md-card-media v-if="false">
                <img :src="vendor.vendor.logo | googleimage" alt class="logo-image">
            </md-card-media>
            <md-card-header>
                <h1 class="md-title" v-if="false">{{vendor.name}}</h1>
                <pre v-if="vendor.vendor && vendor.vendor.welcome">{{vendor.vendor.welcome}}</pre>
            </md-card-header>
        </md-card>
    </div>
</template>
<script>
export default {
    props: {
        vendor: {
            type: Object
        }
    }
};
</script>
<style lang="less">
.vendor-profile-about {
    .md-card {
        .md-card-media {
            padding: 20px;
            .logo-image {
                height: 150px;
                width: auto;
            }
        }
    }
}
</style>

