<template>
    <div class="product-view">
        <loading v-if="loading"></loading>
        <div>
            <div class="cards" v-if="products.length > 0">
                <product-card :key="p._id" :product="p" v-for="p in products"></product-card>
            </div>
            <div class="none" v-if="products.length == 0 && !loading">
                <md-card>
                    <md-card-header>
                        <div style="text-align: center" v-if="!location">
                            <img alt="People" src="/static/party-rental.jpg" />
                            <h2>To get started please select your location above!</h2>
                        </div>

                        <div style="text-align: center" v-if="location && !category">
                            <img alt src="/static/sorry-no-result-found.png" />
                            <h4>Please check the spelling or try searching for something else</h4>
                        </div>

                        <div style="text-align: center" v-if="location && category">
                            <img alt src="/static/coming-soon.png" />
                            <h4>We are adding new products to {{ getCategoryBySlug(category).name }}</h4>
                            <h4 v-if="!vendorMode">
                                If you are a vendor and would like to list your products Click
                                <!-- <a href="https://vendors.eztimerental.com" target="_blank">"Here"</a> -->
                                <router-link to="/account/vendor/signup">Here</router-link>
                            </h4>
                        </div>
                    </md-card-header>
                    <md-card-media>
                        <!-- <img alt="People" class="party-rental" src="/static/party-rental.jpg"> -->
                    </md-card-media>
                </md-card>
            </div>
            <div style="text-align: center">
                <md-button class="md-primary" v-if="loading">
                    <md-icon>arrow_downwards</md-icon>
                    <span>Loading...</span>
                </md-button>
            </div>
        </div>
        <pagination :pageNo="page.pageNo" :pageSize="page.pageSize" :totalPage="page.totalPage" @pagination="onPagination"></pagination>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import Loading from "@/components/Loading";
import Pagination from "@/components/Pagination";
import ProductCard from "@/components/Product/ProductCard";
export default {
    props: {
        vendor: String,
        category: String, //slug
        level: String | Number,
        q: String,
        hotOnly: Boolean,
        saleOnly: Boolean,
        newOnly: Boolean,
    },
    components: {
        Loading,
        Pagination,
        ProductCard,
    },
    data() {
        return {
            loading: true,
            ready: false,
            page: {
                pageNo: 1,
                pageSize: 20,
            },
            categories: [],
            products: [],
        };
    },
    computed: {
        ...mapGetters({
            getCategoryBySlug: "category/getCategoryBySlug",
            location: "location/location",
            defaultLocation: "location/defaultLocation",
            vendorMode: "vendorMode",
            affiliateId: "affiliateId",
        }),
        conditionSlug() {
            return {
                category: this.category,
                q: this.q,
                location: this.location,
                vendor: this.vendor,
            };
        },
    },
    watch: {
        conditionSlug: {
            deep: true,
            handler() {
                console.info("load");
                if (this.ready) {
                    this.page.pageNo = 1;
                    this.loadProducts();
                }
            },
        },
    },
    methods: {
        ...mapActions({
            loadCategories: "category/loadCategories",
            loadLocations: "location/loadLocations",
        }),
        async loadProducts() {
            let location = this.location || this.defaultLocation;
            let category = this.getCategoryBySlug(this.category, this.level);

            if (this.category && !category) {
                this.$store.commit("setMessage", "Category Not Found");
                return;
            }

            console.info("productview", this.vendor);

            let result = await this.$store.dispatch("crud/get", {
                api: "products/home",
                params: {
                    limit: this.limit,
                    category: category ? category._id : undefined,
                    location: location ? location._id : undefined,
                    q: this.q,
                    vendor: this.vendor || (this.vendorMode && this.vendorMode._id),
                    affiliateId: this.affiliateId,
                    hotOnly: this.hotOnly || undefined,
                    newOnly: this.newOnly || undefined,
                    saleOnly: this.saleOnly || undefined,
                    ...this.page,
                },
            });
            this.products = result.data;
            this.page = result.page;
            this.loading = false;
            this.ready = true;
            document.body.scrollTop = document.documentElement.scrollTop = 0;
        },
        onPagination({ page, size }) {
            this.page.pageNo = page;
            this.page.pageSize = size;
            this.loadProducts();
        },
        setSeo() {
            if (this.category) {
                let category = this.getCategoryBySlug(this.category, this.level);
                this.$store.dispatch("seo/set", {
                    headline1: category.name,
                });
            } else {
                this.$store.dispatch("seo/setDefault");
            }
        },
    },

    async created() {
        await this.loadCategories();
        await this.loadLocations();

        await this.loadProducts();
    },
};
</script>

<style lang="less">
.product-view {
    .cards {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 10px;
        border-bottom: 1px solid #efefef;
    }
    .md-card {
        box-shadow: none;
    }
    .none {
        .md-card {
            text-align: center;
            .md-card-header {
                padding: 0;
            }
            .md-card-media {
                padding-top: 0px;
                img {
                    width: 100px;
                }

                .party-rental {
                    width: 800px;
                }
            }
        }
    }
}
</style>
